<template>
  <v-card outlined flat class="mb-4" :loading="loading">
    <v-card-title v-text="'Incident'" />

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'

export default {
  name: 'pibot-eprs-incident-briefing',
  mixins: [eprs],
  data: () => ({
    headers: [
      { text: 'Asset', value: 'pipeline_name', divider: true },
      { text: 'Date of occurance', value: 'date_of_occurance_timestamp' },
      { text: 'Absolute Distance [m]', value: 'absolute_distance' },
      { text: 'Name', value: 'incident_name' },
      { text: 'Description', value: 'incident_description' }
    ]
  }),
  computed: {
    items () {
      return [{ ...this.incident }]
    }
  },
  async beforeMount () {
    const incident = await this.getIncidentById(this.iid)
    incident.date_of_occurance_timestamp = moment(incident.date_of_occurance).format('DD MMM YYYY HH:mm:ss UTC Z')
    this.incident = incident
  }
}
</script>
